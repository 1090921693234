<template>
    <section class="pa-4 formular">
        <!--SECTION-->
        <v-row no-gutters :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4">
            <v-col cols="7" class="pa-3">
                <v-select outlined dense color="gold" ref="cat_sel"
                          :label="g_i18_keys('category')"
                          item-text="alias"
                          item-value="value"
                          :items="select_category"
                          v-model="to_backend.category"
                          hide-details
                          item-color="gold"
                />
            </v-col>
            <v-col cols="5" class="pa-3">
                <v-text-field outlined dense color="gold"
                              :label="g_i18_keys('sort')"
                              v-model="to_backend.sort"
                              hide-details
                />
            </v-col>
        </v-row>

        <!--VIDEO-->
        <v-row no-gutters :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-1">
            <v-col cols="12" class="pa-3">
                <v-textarea v-model="to_backend.title"
                            :label="g_i18_keys('title')"
                            auto-grow hide-details
                            outlined dense color="gold"
                            rows="1"
                />
            </v-col>
        </v-row>
        <v-row no-gutters :class="{ 'rgg-grey' : $vuetify.theme.dark }" class="grey lighten-4 rounded mb-4">
            <v-col cols="12" class="pa-3">
                <v-text-field outlined dense color="gold" prepend-inner-icon="mdi-youtube"
                              :label="$t('content_management.dialog.video_id')"
                              v-model="to_backend.videoId"
                              hide-details
                />
                <v-row no-gutters class="ma-4">
                    <v-col cols="12" align="center">
                        <iframe :id="'video'" type="text/html" width="640" height="360"
                                :src="'https://www.youtube.com/embed/' + to_backend.videoId"
                                frameborder="0"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!--BUTTONS-->
        <v-row no-gutters v-if="!edit">
            <v-col cols="12" align="center">
                <v-btn depressed color="gold" dark class="px-8" @click="create_content">
                    {{ $t('common.create') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters v-else>
            <v-col cols="4"></v-col>
            <v-col cols="4" align="center">
                <v-btn depressed color="gold" dark class="px-4" @click="update_content">
                    {{ $t('common.save') }}
                </v-btn>
            </v-col>
            <v-col cols="4" align="right">
                <rgg-confirm :confirm="delete_content" type="delete" message="content_management.dialog.delete_confirm_message" />
            </v-col>
        </v-row>
    </section>
</template>

<script>
    import {createContent, updateContent, deleteContent} from "@/api/content";

    export default {
        name: "ContentVideoDialog",
        props: {
            edit: Boolean,
            id: Number,
            card_item: Object
        },
        data: () => ({
            to_backend: {
                title: '',
                category: 'Videos',
                sort: '0',
                type: 'Video',
                videoId: ''
            },
            select_category: []
        }),
        created() {
            this.select_category = [
                {alias: this.g_i18_filter('videos'), value: 'Videos'},
                {alias: this.g_i18_filter('homeseller'), value: 'HomeSeller'},
                {alias: this.g_i18_filter('homecustomer'), value: 'HomeCustomer'}
            ]

            if(this.edit) {
                let title = this.$t('content_management.dialog.edit_video') +' ID:' + this.id;
                this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

                for (const prop of Object.keys(this.to_backend)) {
                    if(prop === 'videoId') {
                        this.to_backend.videoId = this.card_item.raw_data.file;
                        continue;
                    }
                    this.to_backend[prop] = this.card_item.raw_data[prop];
                }
            }

        },
        methods: {
            create_content() {
                createContent(this.to_backend)
                    .then( () => {
                        this.$parent.$emit('action_dialog', {func: 'close', data: 'content_created'});
                    })
            },

            update_content() {
                updateContent(this.id, this.to_backend)
                    .then( () => {
                        this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_updated'});
                    })
            },

            delete_content() {
                deleteContent(this.id)
                    .then( () => {
                        this.$parent.$emit('table_dialog', {func: 'closeDialog', data: 'content_deleted'});
                    })
            }
        }
    }
</script>